* {
  box-sizing: border-box;
}
html,
body {
  font-size: 16px;
}

body {
  background: rgba(94, 94, 94, 0.2);
  padding: 0;
  margin: 0;
  font-family: "Muli", sans-serif;
}

.App {
  margin: 0;
  padding: 0;
}
